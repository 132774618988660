body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.justifyandcenter {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}


.Container_Board__3YEgs {
  height: 602px !important;
  width: 602px !important;
  background: #fff;
}

/* 
Final Code STARt
*/


.hover-poiter {
  cursor: pointer;
}


.greenplayer #cube div {
  background: hsl(125.08deg 91.55% 27.84%) !important;
  border: 2px solid #05a913 !important;
}

/* 
Final Code END
*/


/*  */
/*  */
/*  */
.Container_Die__2mSFc {
  /* transform: translateZ(10vw) rotateX(360deg) rotateY(450deg); */
}


@media (max-width: 431px) {

  .Container_Board__3YEgs {
    height: 363px !important;
    width: 430px !important;
    display: flex;
    justify-content: center;
  }


  .ludo-plaers-outer-section {
    width: 150px !important;
    height: 144px !important;
  }


  .ludo-plaers-section {
    height: 85px !important;
    width: 100px !important;
  }

  .ludo-plaers-section .CoinPlaceholder_Container__ntwgA {
    width: 49px !important;
    height: 43px !important;
  }


  .horizontal-cells {
    width: 150px !important;
    height: 75px !important;
  }

  .horizontal-cells .Cell_Container__2x5Zx {
    width: 25px !important;
    height: 25px !important;
  }


  .vertical-cells {
    height: 118px !important;
    width: 100px !important;
  }

  .vertical-cells .Cell_Container__2x5Zx {
    height: 24px !important;
    width: 33.3px !important;

    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: 3px;
  }

  .horizontal-cells .Cell_Container__2x5Zx .Coin_Container__209oi,
  .vertical-cells .Cell_Container__2x5Zx .Coin_Container__209oi {
    height: 23px !important;
    width: 23px !important;
    position: relative;
    top: -4px !important;
  }


  /* center section */
  /* .Container_Container__1KlbC,.Container_HomeContainer__yUxds{
    width: 100px !important;
  } */
  .Container_Container__1KlbC {
    width: 100px !important;
    height: 75px !important;
    background: rgb(227, 222, 59);
  }

  /* .Container_Container__1KlbC .Container_HomeContainer__yUxds {
    border-width: 47px 47px 0px !important;
  } */


  /* center-of-center */
  .center-of-center .BASE_2 {
    border-width: 57px 51px 0px !important;
    left: -1px;
  }

  .center-of-center .BASE_3 {
    top: 38px !important;
    border-width: 38px 50px 0px !important;
    left: -1px;
  }

  .center-of-center .BASE_4 {
    border-width: 56px 38px 0px !important;
    left: 34px !important;
    top: 12px !important;
  }

  .center-of-center .BASE_1 {
    border-width: 49px 37px 0px !important;
    right: 38.4px !important;
    top: 13.3px !important;
  }
}
/* 375 */
@media (max-width: 405px) {

  .Container_Board__3YEgs {
    height: 100% !important;
    width: 337px !important;
  }
  .ludo-plaers-outer-section {
    width: 137px !important;
    height: 130px !important;
}
.ludo-plaers-section {
  height: 90px !important;
  width: 94px !important;
}
.vertical-cells {
  height: 60px !important;
  width: 60px !important;
}
.vertical-cells .Cell_Container__2x5Zx {
  height: 22px !important;
  width: 20px !important;
  background-size: 18px 18px;
  background-repeat: no-repeat;
  background-position: 1px;
}
.ludo-plaers-section .CoinPlaceholder_Container__ntwgA {
  width: 46px !important;
  height: 46px !important;
}
.CoinPlaceholder_Circle__GZwTB {
  height: 20px !important;
  width: 25px !important;
}

.Coin_Container__209oi,  .horizontal-cells .Cell_Container__2x5Zx .Coin_Container__209oi, .vertical-cells .Cell_Container__2x5Zx .Coin_Container__209oi  {
  height: 19px !important;
  width: 19px !important;
}
.vertical-cells .Cell_MultipleCoins__17l4m .Coin_Container__209oi{
  width: 10px !important;
  height: 10px !important;
}



.horizontal-cells {
  width: 136px !important;
  height: 60px !important;
}
.horizontal-cells .Cell_Container__2x5Zx {
  width: 22.66px !important;
  height: 20px !important;
}
/* center */
.Container_Container__1KlbC {
  width: 60px !important;
  height: 60px !important;
}
/* green */
.center-of-center .BASE_2 {
  border-width: 33px 32px 0px !important;
  left: -1px;
  top: -0.5px;
}
/* yellow */
.center-of-center .BASE_4 {
  border-width: 31px 31px 0px !important;
  left: 14px !important;
  top: 16px !important;
}
/* red */
.center-of-center .BASE_3 {
  top: 28px !important;
  border-width: 32px 33px 0px !important;
  left: -2px;
}
/* blue */
.center-of-center .BASE_1 {
  border-width: 32px 29px 0px !important;
  right: 15.4px !important;
  top: 13.3px !important;
}
}
@media only screen and (max-width:425px) {
  .vertical-cells .Cell_MultipleCoins__17l4m .Coin_Container__209oi {
    width: 12px !important;
    height: 12px !important;
  }
}
.Coin_Container__209oi{
/* animation: jump 2s ease !important;  */
  /* animation: jump 2.5s ease forwards !important; */
  /* animation: jump 3s cubic-bezier(0.68, -0.55, 0.27, 1.55) forwards; */
}

/* @keyframes jump {
 0% {
   transform: translateY(0);
 }
 50% {
   transform: translateY(-10px);
 }
 100% {
   transform: translateY(0);
 }
} */


/* @keyframes jump {
  0% {
    transform: translateY(0);
  }
  30% {
    transform: translateY(-15px);
  }
  50% {
    transform: translateY(-10px); 
  }
  70% {
    transform: translateY(-5px); 
  }
  100% {
    transform: translateY(0);
  }
} */


/* 
@keyframes jump {
  0%   { transform: translateY(0); }
  30%  { transform: translateY(-100px); }
  50%  { transform: translateY(0); }
  100% { transform: translateY(0); }
} */



/* .Coin_Container__209oi {
  animation: jump 2s ease forwards !important;
}
.Coin_Container__209oi{
  transform: rotate(315deg) !important;
}


@keyframes jump {
  0% {
      transform: translateY(-20px); 
  }
  100% {
      transform: translateY(0); 
  }
} */

.Coin_Container__209oi {
  /* animation: jump 3s cubic-bezier(0.42, 0, 0.58, 1) forwards; */
  /* animation: jump 1s cubic-bezier(0.42, 0, 0.58, 1); */
}




@keyframes jumpAndZoom {
  0% {
    transform: translateY(-110px) scale(2.7) rotate(317deg);
  }
  30% {
    transform: translateY(-70px) scale(2.2) rotate(317deg);
  }
  50% {
    transform: translateY(-55px) scale(1.7) rotate(317deg);
  }
  70% {
    transform: translateY(-35px) scale(1.3) rotate(317deg);
  }
  100% {
    transform: translateY(0) scale(1) rotate(317deg);
  }
}




/* .token {
  transition: all 0.2s ease;
} */

.Coin_Container__209oi{
  animation: jumpAndZoom 0.3s ease-out forwards;
}
/* 

@keyframes jump {
  0% { transform: translateY(0); }
  50% { transform: translateY(-20px); }
  100% { transform: translateY(0); }
}

.jump {
  animation: jump 0.5s ease-in-out;
} */
.Container_RetiredCoins__X3bzG {
  display: grid !important;
      grid-template-rows: 1fr 1fr;
      grid-auto-flow: column;
      grid-row-gap: 60px;
      margin: 10px;
      grid-column-gap:60px
}
.container{
  border: 0px;
}
@media only screen and (max-width:768px){
  .Container_RetiredCoins__X3bzG {
        grid-row-gap: 45px;
        grid-column-gap:60px
  }
  .Container_RetiredCoins__X3bzG {
    margin: 10px !important;
  }
}
.Container_Container__1iaPB {
  margin: auto;
  height: 100%;
  position: absolute;
  margin: 0px !important;
}
.Container_Container__3WlE9{
  position: relative;
}
@media only screen and (max-width:430px){
  .Container_RetiredCoins__X3bzG .Coin_Container__209oi{
    height: 23px !important;
    width: 23px !important;
  }
  .Container_RetiredCoins__X3bzG {
    grid-row-gap: 30px;
    grid-column-gap:50px
}
.Container_RetiredCoins__X3bzG {
  margin: 3px !important;
}
}