@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.justifyandcenter {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}


.Container_Board__3YEgs {
  height: 602px !important;
  width: 602px !important;
  background: #fff;
}

/* 
Final Code STARt
*/


.hover-poiter {
  cursor: pointer;
}


.greenplayer #cube div {
  background: hsl(125.08deg 91.55% 27.84%) !important;
  border: 2px solid #05a913 !important;
}

/* 
Final Code END
*/


/*  */
/*  */
/*  */
.Container_Die__2mSFc {
  /* transform: translateZ(10vw) rotateX(360deg) rotateY(450deg); */
}


@media (max-width: 431px) {

  .Container_Board__3YEgs {
    height: 363px !important;
    width: 430px !important;
    display: flex;
    justify-content: center;
  }


  .ludo-plaers-outer-section {
    width: 150px !important;
    height: 144px !important;
  }


  .ludo-plaers-section {
    height: 85px !important;
    width: 100px !important;
  }

  .ludo-plaers-section .CoinPlaceholder_Container__ntwgA {
    width: 49px !important;
    height: 43px !important;
  }


  .horizontal-cells {
    width: 150px !important;
    height: 75px !important;
  }

  .horizontal-cells .Cell_Container__2x5Zx {
    width: 25px !important;
    height: 25px !important;
  }


  .vertical-cells {
    height: 118px !important;
    width: 100px !important;
  }

  .vertical-cells .Cell_Container__2x5Zx {
    height: 24px !important;
    width: 33.3px !important;

    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: 3px;
  }

  .horizontal-cells .Cell_Container__2x5Zx .Coin_Container__209oi,
  .vertical-cells .Cell_Container__2x5Zx .Coin_Container__209oi {
    height: 23px !important;
    width: 23px !important;
    position: relative;
    top: -4px !important;
  }


  /* center section */
  /* .Container_Container__1KlbC,.Container_HomeContainer__yUxds{
    width: 100px !important;
  } */
  .Container_Container__1KlbC {
    width: 100px !important;
    height: 75px !important;
    background: rgb(227, 222, 59);
  }

  /* .Container_Container__1KlbC .Container_HomeContainer__yUxds {
    border-width: 47px 47px 0px !important;
  } */


  /* center-of-center */
  .center-of-center .BASE_2 {
    border-width: 57px 51px 0px !important;
    left: -1px;
  }

  .center-of-center .BASE_3 {
    top: 38px !important;
    border-width: 38px 50px 0px !important;
    left: -1px;
  }

  .center-of-center .BASE_4 {
    border-width: 56px 38px 0px !important;
    left: 34px !important;
    top: 12px !important;
  }

  .center-of-center .BASE_1 {
    border-width: 49px 37px 0px !important;
    right: 38.4px !important;
    top: 13.3px !important;
  }
}
/* 375 */
@media (max-width: 405px) {

  .Container_Board__3YEgs {
    height: 100% !important;
    width: 337px !important;
  }
  .ludo-plaers-outer-section {
    width: 137px !important;
    height: 130px !important;
}
.ludo-plaers-section {
  height: 90px !important;
  width: 94px !important;
}
.vertical-cells {
  height: 60px !important;
  width: 60px !important;
}
.vertical-cells .Cell_Container__2x5Zx {
  height: 22px !important;
  width: 20px !important;
  background-size: 18px 18px;
  background-repeat: no-repeat;
  background-position: 1px;
}
.ludo-plaers-section .CoinPlaceholder_Container__ntwgA {
  width: 46px !important;
  height: 46px !important;
}
.CoinPlaceholder_Circle__GZwTB {
  height: 20px !important;
  width: 25px !important;
}

.Coin_Container__209oi,  .horizontal-cells .Cell_Container__2x5Zx .Coin_Container__209oi, .vertical-cells .Cell_Container__2x5Zx .Coin_Container__209oi  {
  height: 19px !important;
  width: 19px !important;
}
.vertical-cells .Cell_MultipleCoins__17l4m .Coin_Container__209oi{
  width: 10px !important;
  height: 10px !important;
}



.horizontal-cells {
  width: 136px !important;
  height: 60px !important;
}
.horizontal-cells .Cell_Container__2x5Zx {
  width: 22.66px !important;
  height: 20px !important;
}
/* center */
.Container_Container__1KlbC {
  width: 60px !important;
  height: 60px !important;
}
/* green */
.center-of-center .BASE_2 {
  border-width: 33px 32px 0px !important;
  left: -1px;
  top: -0.5px;
}
/* yellow */
.center-of-center .BASE_4 {
  border-width: 31px 31px 0px !important;
  left: 14px !important;
  top: 16px !important;
}
/* red */
.center-of-center .BASE_3 {
  top: 28px !important;
  border-width: 32px 33px 0px !important;
  left: -2px;
}
/* blue */
.center-of-center .BASE_1 {
  border-width: 32px 29px 0px !important;
  right: 15.4px !important;
  top: 13.3px !important;
}
}
@media only screen and (max-width:425px) {
  .vertical-cells .Cell_MultipleCoins__17l4m .Coin_Container__209oi {
    width: 12px !important;
    height: 12px !important;
  }
}
.Coin_Container__209oi{
/* animation: jump 2s ease !important;  */
  /* animation: jump 2.5s ease forwards !important; */
  /* animation: jump 3s cubic-bezier(0.68, -0.55, 0.27, 1.55) forwards; */
}

/* @keyframes jump {
 0% {
   transform: translateY(0);
 }
 50% {
   transform: translateY(-10px);
 }
 100% {
   transform: translateY(0);
 }
} */


/* @keyframes jump {
  0% {
    transform: translateY(0);
  }
  30% {
    transform: translateY(-15px);
  }
  50% {
    transform: translateY(-10px); 
  }
  70% {
    transform: translateY(-5px); 
  }
  100% {
    transform: translateY(0);
  }
} */


/* 
@keyframes jump {
  0%   { transform: translateY(0); }
  30%  { transform: translateY(-100px); }
  50%  { transform: translateY(0); }
  100% { transform: translateY(0); }
} */



/* .Coin_Container__209oi {
  animation: jump 2s ease forwards !important;
}
.Coin_Container__209oi{
  transform: rotate(315deg) !important;
}


@keyframes jump {
  0% {
      transform: translateY(-20px); 
  }
  100% {
      transform: translateY(0); 
  }
} */

.Coin_Container__209oi {
  /* animation: jump 3s cubic-bezier(0.42, 0, 0.58, 1) forwards; */
  /* animation: jump 1s cubic-bezier(0.42, 0, 0.58, 1); */
}




@-webkit-keyframes jumpAndZoom {
  0% {
    transform: translateY(-110px) scale(2.7) rotate(317deg);
  }
  30% {
    transform: translateY(-70px) scale(2.2) rotate(317deg);
  }
  50% {
    transform: translateY(-55px) scale(1.7) rotate(317deg);
  }
  70% {
    transform: translateY(-35px) scale(1.3) rotate(317deg);
  }
  100% {
    transform: translateY(0) scale(1) rotate(317deg);
  }
}




@keyframes jumpAndZoom {
  0% {
    transform: translateY(-110px) scale(2.7) rotate(317deg);
  }
  30% {
    transform: translateY(-70px) scale(2.2) rotate(317deg);
  }
  50% {
    transform: translateY(-55px) scale(1.7) rotate(317deg);
  }
  70% {
    transform: translateY(-35px) scale(1.3) rotate(317deg);
  }
  100% {
    transform: translateY(0) scale(1) rotate(317deg);
  }
}




/* .token {
  transition: all 0.2s ease;
} */

.Coin_Container__209oi{
  -webkit-animation: jumpAndZoom 0.3s ease-out forwards;
          animation: jumpAndZoom 0.3s ease-out forwards;
}
/* 

@keyframes jump {
  0% { transform: translateY(0); }
  50% { transform: translateY(-20px); }
  100% { transform: translateY(0); }
}

.jump {
  animation: jump 0.5s ease-in-out;
} */
.Container_RetiredCoins__X3bzG {
  display: grid !important;
      grid-template-rows: 1fr 1fr;
      grid-auto-flow: column;
      grid-row-gap: 60px;
      margin: 10px;
      grid-column-gap:60px
}
.container{
  border: 0px;
}
@media only screen and (max-width:768px){
  .Container_RetiredCoins__X3bzG {
        grid-row-gap: 45px;
        grid-column-gap:60px
  }
  .Container_RetiredCoins__X3bzG {
    margin: 10px !important;
  }
}
.Container_Container__1iaPB {
  margin: auto;
  height: 100%;
  position: absolute;
  margin: 0px !important;
}
.Container_Container__3WlE9{
  position: relative;
}
@media only screen and (max-width:430px){
  .Container_RetiredCoins__X3bzG .Coin_Container__209oi{
    height: 23px !important;
    width: 23px !important;
  }
  .Container_RetiredCoins__X3bzG {
    grid-row-gap: 30px;
    grid-column-gap:50px
}
.Container_RetiredCoins__X3bzG {
  margin: 3px !important;
}
}
body {
    font-family: 'Poppins', sans-serif !important;
    background: #202136;
    background: var(--body-color);
    height: 100vh;
}

:root {
    --body-color: #202136;
    --primary-color: #131420;
    --sidebar-color: #202136;
    --sidebar-user: #0000008c;
    --text-color: #ffffff;
    --input-bg: #ffffff;
    --input-text: #848484;
    --table-color: #0006;
    --table-heading: #ffffff;
    --search-text: #000000;
    --pagination-active-border:#EE1600 ;
    --pagination-bg:   #919EAB;
    --pagination-active-border:#EE1600 ;
    --pagination-text-color:#EE1600 ;
    --dropdown-option-hover: #D7D7D7;
    --dropdown-option-hover-txt:  #000000;
    --dropdown-border: #FFFFFF;



}

[data-theme='dark'] {
    --body-color: #ffffff;
    --primary-color: #ab1d37;
    --sidebar-color: #757575;
    --sidebar-user: #ab1d37;
    --text-color: #ab1d37;
    --input-bg: #ab1d37;
    --input-text: #ffffff;
    --table-color: #212121;
    --table-heading: #757575;
    --search-text: #ffffff;
    --pagination-bg:  #757575;
    --pagination-active-border: #AB1D37;
    --pagination-text-color: #AB1D37;
    --dropdown-option-hover: #AB1D37;
    --dropdown-border: #AB1D37;
    --dropdown-option-hover-txt:  #ffffff;
  
}

/*Reset styles*/
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0 !important;
    font-size: inherit !important;
    font-weight: normal !important;
    line-height: normal !important;
    color: inherit !important;
}

/*Hero Section*/
.hero-container {
    position: relative;
    margin-bottom: 20px;
    display: flex;
    height: 200px;
    width: 100%;
    align-items: center;
    justify-content: center;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.hero-title {
    position: relative;
    z-index: 10;
    font-size: 30px !important;
    font-weight: 600 !important;
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}

.hero-overlay-bg {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgb(0 0 0 / var(--tw-bg-opacity));
    --tw-bg-opacity: 0.5;
}

* {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.15s;
}
.outer-wrapper {
    background-color: var(--body-color);
}

.header {
    position: fixed;
    z-index: 2000000;
    width: 100%;
}

.main-outlet {
    overflow-y: auto;
    padding-top: 77px;
    height: 100vh;
}

main {
    overflow-y: scroll;
}
.main-modal {
    /*@apply fixed inset-0 z-50 flex items-end xl:items-center xl:justify-center bg-black/50 transition-opacity duration-300;*/
    position: fixed;
    inset: 0;
    z-index: 50;
    display: flex;
    align-items: end;
    background-color: rgba(0, 0, 0, 0.5);
    transition: opacity 300ms ease;

}

@media (min-width: 1280px) {
    .main-modal {
        justify-content: center;
        align-items: center;
    }
}


.sidebar-right {
    width: 253px;
    top: 78px;
    background-color: var(--sidebar-color);
    color: white;
    position: fixed;
    height: 100vh;
    right: 0;
    z-index: 99999;
    transition: transform 0.3s ease;
}

.sidebar-right.translate-out {
    transform: translateX(200%);
}

.sidebar-right.translate-in {
    transform: translateX(0);
}


.user-name-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: var(--sidebar-user);

}

.user-name {
    text-align: left;
    font-size: 12px;
    font-weight: 600;
    line-height: 15px;
}

.menu-item {
    display: flex;
    align-items: center;
    margin-left: 11px;
    margin-right: 11px;
    padding: 8.5px;
    border-radius: 2px;
    cursor: pointer;
}

ul {
    list-style: none !important;
    margin: 0 !important;
    padding: 0 !important;
}

a {
    text-decoration: none !important;
    color: white !important;
}

span {
    margin-left: 8px !important;
}

a.active {
    background: black;
    border-radius: 2px;
}

a:hover {
    background: black;
    border-radius: 2px;
}

.icon {
    height: 17px;
    width: 17px;
}

.menu-text {
    margin-left: 2px;
    font-weight: 400;
    font-size: 11px;
    line-height: 16.5px;
}
header {
    background: var(--primary-color);
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
}

.main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px 20px;
    color: white
}

.left-side-wrapper {
    grid-gap: 12px !important;
    gap: 12px !important;
}

.right-side-wrapper {
    grid-gap: 8px !important;
    gap: 8px !important;
}

h6 {
    font-size: 10px !important;
    line-height: 15px !important;
}

h4 {
    font-size: 12px !important;
    padding-bottom: 2px;
    margin-bottom: 0 !important;
}

@media only screen and (max-width:375px){
    .left-side-wrapper img{
        width: 20px !important;
    }
}
.round-wrapper {
    width: 100%;
    height: 100%;
}


.round-history-img {
    background-image: url(/round-history/ludo-hero-img.jpg) !important;
}

.history-cards-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-row-gap: 15px;
    row-gap: 15px;
    padding-left: 18px;
    padding-right: 18px;
    padding-bottom: 5rem;
}

.history-card-styles {
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
    border-radius: 10px;
    --tw-bg-opacity: 1;
    background-color: rgb(19 20 32 / var(--tw-bg-opacity));
    padding: 17px 15px 17px 28px;
}

.card-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    grid-row-gap: .25rem;
    row-gap: .25rem;
    font-weight: 600;
}

.game-id {
    font-size: 15px;
    margin-left: 0 !important;
}

.game-date {
    font-size: 12px;
    --tw-text-opacity: 1;
    color: rgb(75 76 82 / var(--tw-text-opacity));
    margin-left: 0 !important;
}

.score {
    color: #19FB9B;
    font-size: 15px;
}

.score-loss {
    color: #EE1600;
}

.game-status {
    font-size: 12px;
    text-transform: capitalize;
}



.layout-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 1rem;
}

.dropdown-layout {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: .375rem;
    gap: .375rem;
    padding-left: .5rem;
    padding-right: .5rem;
}

.dropdown-label {
    display: block;
    font-size: 10px;
    font-weight: 500;
    color: var(--text-color);
}

.dropdown-container {
    position: relative;
    display: inline-block;
}

.dropdown-button {
    display: flex;
    height: 26px;
    width: 50px;
    --tw-translate-y: -3px;
    transform: translate(var(--tw-translate-y));
    cursor: pointer;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    grid-gap: 1.25rem;
    gap: 1.25rem;
    border-radius: 2px;
    border: 1px solid var(--text-color);
    --tw-border-opacity: 1;
    background-color: transparent;
    padding: 0 6px !important;
    font-size: 10px;
    font-weight: 500;
    --tw-text-opacity: 1;
    color: var(--text-color);
}

.dropdown-button img {
    position: absolute;
    right: 5px;
    top: 10px;
    margin-bottom: .125rem;
    height: 5.29px;
    width: 10px;
}

.dropdown-txt {
    
    margin-left: 15px !important;
   

}

.dropdown-div {
    position: absolute;
    z-index: 1;
   
    display: block !important;
    --tw-bg-opacity: 1;
    background-color:  #FFFFFF;
    
    --tw-shadow: 0px 8px 16px rgba(0, 0, 0, .1);
    box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    transition: opacity 0.3s ease, visibility 0.3s ease; /* For smooth opening/closing */
    opacity: 1; /* Default is visible */
    visibility: visible;
    width: 49px;
    text-align: center;
    margin-left: -3px;
    margin-top: 4px;
  
  
    
}

.dropdown-div.hidden {
    opacity: 0; /* When hidden */
    visibility: hidden;
}

.dropdown-option {
    display: block;
    height: 19px;
    white-space: nowrap;
    --tw-bg-opacity: 1;

  
 text-align: center  !important ;
    font-size: 10px   !important;
    font-weight: 500 !important;
align-content: center;
    color: rgb(0 0 0 / var(--tw-text-opacity)) !important;
    text-decoration: none !important;
    cursor: pointer;
    color: #0000 ;
}

.dropdown-option:hover {
    background-color: var(--dropdown-option-hover)  !important;  
    color: var(--dropdown-option-hover-txt)  !important ;
    text-align: center !important ;
    flex: content;
    justify-content: center;
    align-items: center;
    border-radius: 0%;

   
}

.Txt {
    margin-top: 1px;
    display: block;
    font-size: 10px;
    font-weight: 500;
    --tw-text-opacity: 1;
    color: var(--text-color);
}

.search-filter-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-left: 1rem;
    padding-right: 1rem;

}

.search-img-container {
    position: relative;
    display: flex;
    align-items: center;
    grid-column-gap: .25rem;
    -webkit-column-gap: .25rem;
            column-gap: .25rem;
}

.search-input {
    height: 28px;
    width: 130px;
    border-radius: 2px;
    border-style: none;
    padding-left: .5rem;
    padding-right: 2rem;
    font-size: .75rem;
    line-height: 1rem;
    outline: 2px solid transparent;
    outline-offset: 2px;
    background-color: var(--input-bg);
    color: var(--search-text);
}

.search-input::-webkit-input-placeholder {
    color: var(--input-text);
}

.search-input:-ms-input-placeholder {
    color: var(--input-text);
}

.search-input::-ms-input-placeholder {
    color: var(--input-text);
}

.search-input::placeholder {
    color: var(--input-text);
}

.search-img-style {
    position: absolute;
    top: .625rem;
    right: .5rem;
    width: .5rem;
    height: .5rem;
    background-size: cover;
}

.pagination-cards-container > * {
    font-family: 'Inter', sans-serif;
    cursor: pointer;
    display: flex;
   text-align: center;
    width: 2rem;
    height: 2rem;
    align-items: center;
    justify-content: center;
    border-radius: .25rem;
    border-width: 1px;
    border-style: solid;
    --tw-border-opacity: 1;
    border-color: rgb(132 132 132 / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / 1);
    font-size: .875rem;
    line-height: 1.25rem;
    font-weight: 700;
    --tw-text-opacity: 1;
    color: rgb(132 132 132 / var(--tw-text-opacity));
}

.pagination-cards-container {
   
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-column-gap: .5rem;
    -webkit-column-gap: .5rem;
            column-gap: .5rem;
    padding-bottom: 2.5rem;
}
.TXT {
    margin-right: 8px;
}
.pagination-bg-color {
    background-color: var(--pagination-bg);
}

.pagination-border {
    border-color: var( --pagination-active-border);
    color:  var(--pagination-text-color)
}
section {
    /* @apply bg-[202136] min-h-screen */
}

.main-wrapper {
    height: 100%;
    width: 100%;
    border-radius: .5rem;
    padding: .75rem;
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}

/* Title section */
.profit-loss-img {
    background-image: url(/profit-loss/profit-loss.png) !important;
}


/* Table */
.table-section {
    height: 100%;
    overflow-x: hidden;
}

.table-row {
    background-color: var(--table-color);
}

table {
    border-collapse: separate !important;
    height: 100%;
    width: 100%;
    table-layout: auto;
    --tw-border-spacing-y: 5px;
    --tw-border-spacing-x: 0;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
    text-align: center;
    font-size: .875rem;
    line-height: 1.25rem;
}

thead {
    font-size: .875rem;
    line-height: 1.25rem;
    font-weight: 600;
}

th {
    padding-left: .5rem;
    padding-right: .5rem;
    font-size: .875rem;
    line-height: 1.25rem;
    font-weight: 600;
    color: var(--table-heading);
}

.table-data {
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: .75rem;
    line-height: 1rem;
    letter-spacing: -.025em;
}

.rounded-custom {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.rounded-custom-right {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.id-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 0;
    gap: 0;
}

.id-row img {
    margin-bottom: .125rem;
    height: .625rem;
    width: 1rem;
}


.win {
    color: #19FB9B;
}

.loss {
    color: #EE1600;
}

/* Play ludo container*/
.ludo-container {
    position: relative;
    display: flex;
    height: 310px;
    width: 100%;
    /* align-items: center;
    justify-content: center; */
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-width: .89px;
    border-bottom-width: 0px;
    border-style: solid;
    --tw-border-opacity: 1;
    border-color: rgb(102 102 102 / var(--tw-border-opacity));
    background-image: url(/profit-loss/ludo-bg.png);
    background-repeat: no-repeat;
}

.ludo-overlay-bg {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

}

.ludo-img {
    z-index: 10000;
}

.action-btn-container {
    margin-bottom: 1.25rem;
    display: flex;
    height: 50px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    grid-gap: .25rem;
    gap: .25rem;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-width: .89px;
    border-top-width: 0px;
    border-style: solid;
    --tw-border-opacity: 1;
    border-color: rgb(102 102 102 / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgb(171 29 55 / var(--tw-bg-opacity));
    padding-left: .25rem;
    padding-right: .25rem;
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}

.action-btns {
    display: flex;
    height: 2.25rem;
    align-items: center;
    justify-content: center;
    border-radius: 33px;
    border-width: 2px;
    border-style: solid;
    --tw-border-opacity: 1;
    border-color: rgb(103 17 33 / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    font-size: 9px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 8px;
    --tw-text-opacity: 1;
    color: rgb(171 29 55 / var(--tw-text-opacity));
}


/* Animation */
.hidden-row {
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    transform: translateY(-20px);
    transition: max-height .6s ease, opacity .4s ease-in-out, transform .4s ease-in-out;
}


.visible-row {
    max-height: 400px;
    opacity: 1;
    transform: translateY(0);
    transition: max-height .6s ease, opacity .4s ease-in-out, transform .4s ease-in-out;
}

@media (min-width: 370px) {
    .action-btns {
        font-size: .75rem;
        line-height: 1rem;
    }
}

@media (min-width: 640px) {
    .table-data {
        font-size: .875rem;
        line-height: 1.25rem;
        letter-spacing: 0em;
    }

    .id-row {
        grid-gap: .25rem;
        gap: .25rem;
    }

    .id-row img {
        margin-bottom: 0;
    }

    .action-btns {
        font-size: 1rem;
        line-height: .75rem;
    }
}
.head-main {
    background-image: url('/home/ludo.svg');
    border-radius: 5px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.head-first {
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 170px;
    border-radius: 5px;
}

.head-h2 {
    font-weight: bold !important;
    font-size: 30px !important;
    color: white !important;
    text-align: center;
    text-wrap: balance;
    line-height: 35px;
}

.main-body-grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 14px;
    gap: 14px;
    padding-top: 15px;
}

@media (min-width: 768px) {
    .main-body-grid {
        grid-template-columns: repeat(3, 1fr);
    }
}

/* 4 columns for large screens and above */
@media (min-width: 1024px) {
    .main-body-grid {
        grid-template-columns: repeat(4, 1fr);
    }
}

.body-img {
    position: absolute;
    inset: 0;
    background: rgba(0, 0, 0, 0.3);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.body-img-head {
    position: absolute;
    top: 11px;
    left: 7px;
    font-size: 7px;
    font-weight: 600;
}

.body-img-head-content {
    background: #ffffffd9;
    display: flex;
    padding: 3.25px 5px;
    border-radius: 2px;

}

.body-img-bottom {
    background-color: #ab1d37;
    display: flex;
    justify-content: space-between;
    align-items: end;
    color: white;
    padding: 8px;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
}

.bottom-text-1 {
    font-size: 12px;
    font-weight: bold;
    line-height: 18px;
}

.bottom-text-2 {
    font-size: 8px;
    font-weight: 300;
    text-transform: uppercase;
    line-height: 12px;
}

.next-game-btn {
    border: 1px solid #565656;
    background: #15191d;
    font-size: 12px;
    width: 100%;
    color: white;
    margin-top: 4px;
    margin-bottom: 4px;
    padding-top: 8px;
    padding-bottom: 8px;
    line-height: 12px;
}
.Coin_Container__209oi {
  border-radius: 50% 50% 50% 0;
  background: #89849b;
  transform: rotate(-45deg);
  box-shadow: 0 0 5px;
  margin-bottom: 50%;
}

.Coin_Container__209oi:after {
  content: '';
  width: 50%;
  height: 50%;
  margin: 25% 0 0 25%;
  background: #FFFFFF;
  position: absolute;
  border-radius: 50%;
  box-shadow: inset -3px 3px 5px #999999;
}

.CoinPlaceholder_Container__ntwgA {
  display: flex;
  align-items: center;
  justify-content: center;
}

.CoinPlaceholder_Circle__GZwTB {
  box-shadow: inset 0px 2px 5px 0px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Container_OuterContainer__3A94X {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Container_InnerContainer__2vc8N {
  display: flex;
  flex-direction: row;
  background-color: #FFFFFF;
  flex-wrap: wrap;
  box-shadow: 0px 2px 5px 0px;
}

.Container_Spawnable__D1Ubh {
  box-shadow: 0px 2px 30px 0px #FFFFFF;
}
.Container_Container__1iaPB {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.Container_Die__2mSFc {
  border-radius: 20%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  padding: 7%;
  box-shadow: 0 0 5px;
  cursor: pointer;
}

.Container_Disabled__3bXXO {
  opacity: 0.5;
  pointer-events: none;
}

.Container_Dot__2a5D9 {
  width: 30%;
  height: 30%;
  background-color: inherit;
  box-shadow: inset 0 0 5px;
  border-radius: 50%;
}

.Container_Invisible__1PNUf {
  box-shadow: none;
}

h1 {
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
}

#cube .front {
    transform:
        translateZ(22px);
}

#cube .back {
    transform:
        rotateX(-180deg) translateZ(22px);
}

#cube .right {
    transform:
        rotateY(90deg) translateZ(22px);
}

#cube .left {
    /* transform:
      rotateY(-90deg)
      translateZ(100px); */
    transform: rotateY(-90deg) translateZ(22px);
}

#cube .top {
    transform:
        rotateX(90deg) translateZ(22px);
}

#cube .bottom {
    transform:
        rotateX(-90deg) translateZ(22px);
}

.container {
    width: 45px;
    height: 5px;
    position: relative;
    margin: 0 auto 40px;
    border: 1px solid #FFF;

    perspective: 1000px;
    perspective-origin: 50% 100%;
}

#cube {
    width: 100%;
    height: 100%;
    /* top: 100px; */
    /* left: 25px; */
    /* position: absolute; */
    transform-style: preserve-3d;
    transition: transform 3s;
    display: flex;
    justify-content: center;
    align-items: center;
}

#cube:hover {
    cursor: pointer;
}

#cube div {
    background: hsl(0deg 64.57% 50.2%);
    display: block;
    position: absolute;
    width: 45px;
    height: 45px;
    border: 2px solid #ab1a1a;

    margin: 0 auto;

    font-family: Arial, Helvetica, sans-serif;
    font-size: 500%;
    text-align: center;
    /* padding: 50px 0; */
}

.dot {
    display: block;
    position: absolute;
    width: 10px;
    height: 10px;
    background: #fff;
    border-radius: 15px;
}

/* .front .dot1 { top: 85px; left: 85px; }
  .back .dot1 { top: 45px; left: 45px; }
  .back .dot2 { top: 125px; left: 125px; }
  .right .dot1 { top: 45px; left: 45px; }
  .right .dot2 { top: 85px; left: 85px; }
  .right .dot3 { top: 125px; left: 125px; }
  .left .dot1 { top: 45px; left: 45px; }
  .left .dot2 { top: 45px; left: 125px; }
  .left .dot3 { top: 125px; left: 45px; }
  .left .dot4 { top: 125px; left: 125px; }
  .top .dot1 { top: 45px; left: 45px; }
  .top .dot2 { top: 45px; left: 125px; }
  .top .dot3 { top: 85px; left: 85px; }
  .top .dot4 { top: 125px; left: 45px; }
  .top .dot5 { top: 125px; left: 125px; }
  .bottom .dot1 { top: 45px; left: 45px; }
  .bottom .dot2 { top: 45px; left: 85px; }
  .bottom .dot3 { top: 45px; left: 125px; }
  .bottom .dot4 { top: 125px; left: 45px; }
  .bottom .dot5 { top: 125px; left: 85px; }
  .bottom .dot6 { top: 125px; left: 125px; } */


/* 1 */
.front .dot.dot1 {
    position: relative;
    top: 15px;
    left: 8px;
}

/* 2 */
.back .dot.dot1 {
    position: relative;
    top: 17px;
    right: 4px;
}

.back .dot.dot2 {
    position: relative;
    top: 6px;
    left: 19px;
}

/* 3 */
.right .dot.dot1 {
    position: relative;
    top: 15px;
    left: 8px;
}

.right .dot.dot2 {
    position: relative;
    top: 5px;
    left: 22px;
}

.right .dot.dot3 {
    position: relative;
    top: -5px;
    right: 7px;
}

/* 4 */
.left .dot.dot1 {
    position: relative;
    top: 25px;
    right: -18px;
}

.left .dot.dot2 {
    position: relative;
    top: -4px;
    right: 2px;
}

.left .dot.dot3 {
    position: relative;
    bottom: 14px;
    left: 17px;
}

.left .dot.dot4 {
    position: relative;
    top: -5px;
    right: 2px;
}

/* 5 */
.top .dot.dot1 {
    position: relative;
    top: 28px;
    right: 4px;
}

.top .dot.dot2 {
    position: relative;
    top: -6px;
    right: 5px;
}

.top .dot.dot3 {
    position: relative;
    bottom: 4px;
    right: -8px;
}

.top .dot.dot4 {
    position: relative;
    top: -3px;
    right: -21px;
}

.top .dot.dot5 {
    position: relative;
    bottom: 36px;
    right: -20px;
}

/* 6 */
.bottom .dot.dot1 {
    position: relative;
    top: 17px;
    right: -0.5px;
}

.bottom .dot.dot2 {
    position: relative;
    top: -6px;
    right: 0px;
}

.bottom .dot.dot3 {
    position: relative;
    bottom: 3px;
    right: -17.5px;
}

.bottom .dot.dot4 {
    position: relative;
    top: -26px;
    left: 17px;
}

.bottom .dot.dot5 {
    position: relative;
    top: -10px;
    right: -1px;
}

.bottom .dot.dot6 {
    position: relative;
    bottom: 20px;
    right: -18px;
}


#cube .Container_Dot__2a5D9 {
    width: 30% !important;
    height: 30% !important;
    background-color: inherit !important;
    box-shadow: inset 0 0 5px !important;
    border-radius: 50% !important;
}

#cube .Container_Invisible__1PNUf {
    box-shadow: none !important;
}


/*  */
/* 1 */
.front-dice-1 .dot.dot1 {
    position: relative;
    top: 15px !important;
    left: 8px !important;
}

/* 2 */
.front-dice-2 .dot.dot1 {
    position: relative;
    top: 16px !important;
    left: -1px !important
}

.front-dice-2 .dot.dot2 {
    position: relative;
    top: 6px !important;
    left: 19px !important;
}

/* 3 */
.front-dice-3 .dot.dot1 {
    position: relative;
    top: 15px !important;
    left: 8px !important;
}

.front-dice-3 .dot.dot2 {
    position: relative;
    top: 5px !important;
    left: 22px !important;
}

.front-dice-3 .dot.dot3 {
    position: relative;
    top: -5px !important;
    right: 7px !important;
}

/* 4 */
.front-dice-4 .dot.dot1 {
    position: relative;
    top: 25px !important;
    left: 17px !important;
}

.front-dice-4 .dot.dot2 {
    position: relative;
    top: -4px !important;
    right: 2px !important;
}

.front-dice-4 .dot.dot3 {
    position: relative;
    bottom: 14px !important;
    left: 17px !important;
}

.front-dice-4 .dot.dot4 {
    position: relative;
    top: -5px !important;
    right: 2px !important;
}

/* 5 */
.front-dice-5 .dot.dot1 {
    position: relative;
    top: 28px !important;
    left: -4px !important;
}

.front-dice-5 .dot.dot2 {
    position: relative;
    top: -6px !important;
    right: 5px !important;
}

.front-dice-5 .dot.dot3 {
    position: relative;
    bottom: 4px !important;
    right: -8px !important;
}

.front-dice-5 .dot.dot4 {
    position: relative;
    top: -3px !important;
    right: -21px !important;
}

.front-dice-5 .dot.dot5 {
    position: relative;
    bottom: 36px !important;
    right: -20px !important;
}

/* 6 */
.front-dice-6 .dot.dot1 {
    position: relative;
    top: 17px !important;
    left: 0.5px !important
}

.front-dice-6 .dot.dot2 {
    position: relative;
    top: -6px !important;
    right: 0px !important;
}

.front-dice-6 .dot.dot3 {
    position: relative;
    bottom: 3px !important;
    right: -17.5px !important;
}

.front-dice-6 .dot.dot4 {
    position: relative;
    top: -26px !important;
    left: 17px !important;
}

.front-dice-6 .dot.dot5 {
    position: relative;
    top: -10px !important;
    right: -1px !important;
}

.front-dice-6 .dot.dot6 {
    position: relative;
    bottom: 20px !important;
    right: -18px !important;
}


.container{
    margin: unset !important;
}
.Container_Container__3WlE9 {
  display: flex;
  flex-grow: 1;
}

.Container_TopPlacement__BBf9q {
  flex-direction: column;
}

.Container_BottomPlacement__xOiZe {
  flex-direction: column-reverse;
}

.Container_Disabled__5h01E {
  opacity: 0.3;
  pointer-events: none;
}

.Container_RetiredCoins__X3bzG {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Container_RetiredCoins__X3bzG > div {
  margin-bottom: 0;
}

.Container_Container__1KlbC {
  position: relative;
}

.Container_HomeContainer__yUxds {
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
}

.Cell_Container__2x5Zx {
  box-shadow: inset 0 0 1px;
  background-size: contain;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
}

.Cell_MultipleCoins__17l4m > div {
  margin-bottom: 0;
}

.Container_Container__2w4-K {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.ContextMenu_Container__SWpvk {
  position: absolute;
  background-color: #ffffff;
  border: 1px solid #ccc;
  padding: 5px 10px;
  color: #555;
  cursor: pointer;
}

.ContextMenu_MenuItem__HA4EW {
  padding: 5px 0;
}

.Container_Container__Xw3an {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.Container_GameContainer__TR133 {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: center;
}

.Container_Board__3YEgs {
  border: 1px solid #CCCCCC;
  display: flex;
  flex-wrap: wrap;
}

.Container_PlayerContainer__aEflL {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Container_GameConfiguration__lxXEh {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  padding: 20px;
}

.Container_Button__9f4zo {
  font-size: 20px;
  padding: 10px;
  color: #555;
  font-family: Arial;
}

