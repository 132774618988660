h1 {
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
}

#cube .front {
    transform:
        translateZ(22px);
}

#cube .back {
    transform:
        rotateX(-180deg) translateZ(22px);
}

#cube .right {
    transform:
        rotateY(90deg) translateZ(22px);
}

#cube .left {
    /* transform:
      rotateY(-90deg)
      translateZ(100px); */
    transform: rotateY(-90deg) translateZ(22px);
}

#cube .top {
    transform:
        rotateX(90deg) translateZ(22px);
}

#cube .bottom {
    transform:
        rotateX(-90deg) translateZ(22px);
}

.container {
    width: 45px;
    height: 5px;
    position: relative;
    margin: 0 auto 40px;
    border: 1px solid #FFF;

    perspective: 1000px;
    perspective-origin: 50% 100%;
}

#cube {
    width: 100%;
    height: 100%;
    /* top: 100px; */
    /* left: 25px; */
    /* position: absolute; */
    transform-style: preserve-3d;
    transition: transform 3s;
    display: flex;
    justify-content: center;
    align-items: center;
}

#cube:hover {
    cursor: pointer;
}

#cube div {
    background: hsl(0deg 64.57% 50.2%);
    display: block;
    position: absolute;
    width: 45px;
    height: 45px;
    border: 2px solid #ab1a1a;

    margin: 0 auto;

    font-family: Arial, Helvetica, sans-serif;
    font-size: 500%;
    text-align: center;
    /* padding: 50px 0; */
}

.dot {
    display: block;
    position: absolute;
    width: 10px;
    height: 10px;
    background: #fff;
    border-radius: 15px;
}

/* .front .dot1 { top: 85px; left: 85px; }
  .back .dot1 { top: 45px; left: 45px; }
  .back .dot2 { top: 125px; left: 125px; }
  .right .dot1 { top: 45px; left: 45px; }
  .right .dot2 { top: 85px; left: 85px; }
  .right .dot3 { top: 125px; left: 125px; }
  .left .dot1 { top: 45px; left: 45px; }
  .left .dot2 { top: 45px; left: 125px; }
  .left .dot3 { top: 125px; left: 45px; }
  .left .dot4 { top: 125px; left: 125px; }
  .top .dot1 { top: 45px; left: 45px; }
  .top .dot2 { top: 45px; left: 125px; }
  .top .dot3 { top: 85px; left: 85px; }
  .top .dot4 { top: 125px; left: 45px; }
  .top .dot5 { top: 125px; left: 125px; }
  .bottom .dot1 { top: 45px; left: 45px; }
  .bottom .dot2 { top: 45px; left: 85px; }
  .bottom .dot3 { top: 45px; left: 125px; }
  .bottom .dot4 { top: 125px; left: 45px; }
  .bottom .dot5 { top: 125px; left: 85px; }
  .bottom .dot6 { top: 125px; left: 125px; } */


/* 1 */
.front .dot.dot1 {
    position: relative;
    top: 15px;
    left: 8px;
}

/* 2 */
.back .dot.dot1 {
    position: relative;
    top: 17px;
    right: 4px;
}

.back .dot.dot2 {
    position: relative;
    top: 6px;
    left: 19px;
}

/* 3 */
.right .dot.dot1 {
    position: relative;
    top: 15px;
    left: 8px;
}

.right .dot.dot2 {
    position: relative;
    top: 5px;
    left: 22px;
}

.right .dot.dot3 {
    position: relative;
    top: -5px;
    right: 7px;
}

/* 4 */
.left .dot.dot1 {
    position: relative;
    top: 25px;
    right: -18px;
}

.left .dot.dot2 {
    position: relative;
    top: -4px;
    right: 2px;
}

.left .dot.dot3 {
    position: relative;
    bottom: 14px;
    left: 17px;
}

.left .dot.dot4 {
    position: relative;
    top: -5px;
    right: 2px;
}

/* 5 */
.top .dot.dot1 {
    position: relative;
    top: 28px;
    right: 4px;
}

.top .dot.dot2 {
    position: relative;
    top: -6px;
    right: 5px;
}

.top .dot.dot3 {
    position: relative;
    bottom: 4px;
    right: -8px;
}

.top .dot.dot4 {
    position: relative;
    top: -3px;
    right: -21px;
}

.top .dot.dot5 {
    position: relative;
    bottom: 36px;
    right: -20px;
}

/* 6 */
.bottom .dot.dot1 {
    position: relative;
    top: 17px;
    right: -0.5px;
}

.bottom .dot.dot2 {
    position: relative;
    top: -6px;
    right: 0px;
}

.bottom .dot.dot3 {
    position: relative;
    bottom: 3px;
    right: -17.5px;
}

.bottom .dot.dot4 {
    position: relative;
    top: -26px;
    left: 17px;
}

.bottom .dot.dot5 {
    position: relative;
    top: -10px;
    right: -1px;
}

.bottom .dot.dot6 {
    position: relative;
    bottom: 20px;
    right: -18px;
}


#cube .Container_Dot__2a5D9 {
    width: 30% !important;
    height: 30% !important;
    background-color: inherit !important;
    box-shadow: inset 0 0 5px !important;
    border-radius: 50% !important;
}

#cube .Container_Invisible__1PNUf {
    box-shadow: none !important;
}


/*  */
/* 1 */
.front-dice-1 .dot.dot1 {
    position: relative;
    top: 15px !important;
    left: 8px !important;
}

/* 2 */
.front-dice-2 .dot.dot1 {
    position: relative;
    top: 16px !important;
    left: -1px !important
}

.front-dice-2 .dot.dot2 {
    position: relative;
    top: 6px !important;
    left: 19px !important;
}

/* 3 */
.front-dice-3 .dot.dot1 {
    position: relative;
    top: 15px !important;
    left: 8px !important;
}

.front-dice-3 .dot.dot2 {
    position: relative;
    top: 5px !important;
    left: 22px !important;
}

.front-dice-3 .dot.dot3 {
    position: relative;
    top: -5px !important;
    right: 7px !important;
}

/* 4 */
.front-dice-4 .dot.dot1 {
    position: relative;
    top: 25px !important;
    left: 17px !important;
}

.front-dice-4 .dot.dot2 {
    position: relative;
    top: -4px !important;
    right: 2px !important;
}

.front-dice-4 .dot.dot3 {
    position: relative;
    bottom: 14px !important;
    left: 17px !important;
}

.front-dice-4 .dot.dot4 {
    position: relative;
    top: -5px !important;
    right: 2px !important;
}

/* 5 */
.front-dice-5 .dot.dot1 {
    position: relative;
    top: 28px !important;
    left: -4px !important;
}

.front-dice-5 .dot.dot2 {
    position: relative;
    top: -6px !important;
    right: 5px !important;
}

.front-dice-5 .dot.dot3 {
    position: relative;
    bottom: 4px !important;
    right: -8px !important;
}

.front-dice-5 .dot.dot4 {
    position: relative;
    top: -3px !important;
    right: -21px !important;
}

.front-dice-5 .dot.dot5 {
    position: relative;
    bottom: 36px !important;
    right: -20px !important;
}

/* 6 */
.front-dice-6 .dot.dot1 {
    position: relative;
    top: 17px !important;
    left: 0.5px !important
}

.front-dice-6 .dot.dot2 {
    position: relative;
    top: -6px !important;
    right: 0px !important;
}

.front-dice-6 .dot.dot3 {
    position: relative;
    bottom: 3px !important;
    right: -17.5px !important;
}

.front-dice-6 .dot.dot4 {
    position: relative;
    top: -26px !important;
    left: 17px !important;
}

.front-dice-6 .dot.dot5 {
    position: relative;
    top: -10px !important;
    right: -1px !important;
}

.front-dice-6 .dot.dot6 {
    position: relative;
    bottom: 20px !important;
    right: -18px !important;
}


.container{
    margin: unset !important;
}