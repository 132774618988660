@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap');

body {
    font-family: 'Poppins', sans-serif !important;
    background: var(--body-color);
    height: 100vh;
}

:root {
    --body-color: #202136;
    --primary-color: #131420;
    --sidebar-color: #202136;
    --sidebar-user: #0000008c;
    --text-color: #ffffff;
    --input-bg: #ffffff;
    --input-text: #848484;
    --table-color: #0006;
    --table-heading: #ffffff;
    --search-text: #000000;
    --pagination-active-border:#EE1600 ;
    --pagination-bg:   #919EAB;
    --pagination-active-border:#EE1600 ;
    --pagination-text-color:#EE1600 ;
    --dropdown-option-hover: #D7D7D7;
    --dropdown-option-hover-txt:  #000000;
    --dropdown-border: #FFFFFF;



}

[data-theme='dark'] {
    --body-color: #ffffff;
    --primary-color: #ab1d37;
    --sidebar-color: #757575;
    --sidebar-user: #ab1d37;
    --text-color: #ab1d37;
    --input-bg: #ab1d37;
    --input-text: #ffffff;
    --table-color: #212121;
    --table-heading: #757575;
    --search-text: #ffffff;
    --pagination-bg:  #757575;
    --pagination-active-border: #AB1D37;
    --pagination-text-color: #AB1D37;
    --dropdown-option-hover: #AB1D37;
    --dropdown-border: #AB1D37;
    --dropdown-option-hover-txt:  #ffffff;
  
}

/*Reset styles*/
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0 !important;
    font-size: inherit !important;
    font-weight: normal !important;
    line-height: normal !important;
    color: inherit !important;
}

/*Hero Section*/
.hero-container {
    position: relative;
    margin-bottom: 20px;
    display: flex;
    height: 200px;
    width: 100%;
    align-items: center;
    justify-content: center;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.hero-title {
    position: relative;
    z-index: 10;
    font-size: 30px !important;
    font-weight: 600 !important;
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}

.hero-overlay-bg {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgb(0 0 0 / var(--tw-bg-opacity));
    --tw-bg-opacity: 0.5;
}

* {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.15s;
}